<template>
  <!-- 整体内容 -->
  <div class="wrap x-f" v-loading="loading" id="wrap">
    <!-- 左边树装选择 -->
    <categoryTreeCard categoryTreeTitle="商品资料" :isGoods="true" @treeNodeId="getTreeNodeId" @appendTree="appendTree"
      @editTree="editTree" @removeTree="removeTree" :isEditBtn="true" :boxHeight="boxHeight"
      :boxInternalHeight="boxInternalHeight" :categoryTreeOptions="categoryTreeOptions" />
    <!-- 表格和搜索右边盒子 -->
    <div class="rightBox y-b">
      <!-- 表格搜索条件 -->
      <cardTitleCom cardTitle="查询" class="headGoodsSearch" id="search-card">
        <template slot="cardContent">
          <div class="navSearch">
            <!-- 高级搜索按钮 -->
            <seniorSearch v-model="queryParams" :getList="getList" @isShowHighCom="getIsShowHigh"
              :dropDownList="dropDownList" :isChildLevelIds="true" :isBillDate="false" :isSearchInput="true"
              isSearchInputTitle="输入商品编码、名称、条码查询" />
            <div v-show="showHigh">
              <!-- 第一行 -->
              <div class="marT10 marB10">
                <span class="fS14MR10 marL15">商品类型：</span>
                <!-- <el-select
                  class="inputWidth marR15"
                  v-model="queryParams.goodsTypes"
                  size="mini"
                  filterable
                  clearable
                  placeholder="请选择商品类型"
                >
                  <el-option
                    v-for="dict in dict.type.goods_type"
                    :key="dict.value"
                    :label="dict.label"
                    :value="dict.value"
                  />
                </el-select> -->
                <SelectLocal class="marR15" :multiple="true" v-model="queryParams.goodsTypes" style="width: 170px"
                  :option="{
                    data: dict.type.goods_type,
                    value: 'value',
                    label: 'label',
                  }" />
                <span class="fS14MR10">商品状态：</span>
                <el-select size="mini" filterable class="inputWidth marR15" v-model="queryParams.status" clearable
                  placeholder="请选择商品状态">
                  <el-option v-for="dict in dict.type.common_status" :key="dict.value" :label="dict.label"
                    :value="dict.value" />
                </el-select>
                <span class="fS14MR10">计价方式：</span>
                <el-select size="mini" filterable class="inputWidth marR15" v-model="queryParams.pricingMode"
                  placeholder="请选择计价方式" clearable>
                  <el-option v-for="dict in dict.type.pricing_mode" :key="dict.value" :label="dict.label"
                    :value="dict.value" />
                </el-select>
                <span class="fS14MR10">是否管库存：</span>
                <el-select size="mini" filterable class="inputWidth marR15" v-model="queryParams.isManageStock"
                  placeholder="请选择是否管库存" clearable>
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="0"></el-option>
                </el-select>
              </div>
              <div>
                <span class="fS14MR10">保质期天数：</span>
                <el-input size="mini" class="inputWidth" placeholder="保质期天数" maxlength="10"
                  v-model="queryParams.qualityDays" />
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="商品信息" class="headGoodsTable">
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <!-- 操作按钮 -->
            <operatingButton :getList="handleEvent" :isRecycleBtn="true" :isDeleteBtn="false" :multiple="multiple"
              :isBatchEditorBtn="true" :isImportBtn="true" :isExportBtn="true" @handleExport="handleExport"
              @handleImport="openToChannel = true" @handleAdd="handleAdd" @batchEditor="batchEditor"
              @handleDelete="handleDelete" @handleRedoDelete="dialogOptions.show = true">
              <template slot="specialDiyBtn">
                <el-button style="color: #fff; background-color: #12cc9f" icon="el-icon-document-copy"
                  :disabled="multiple" @click="getCopyOrder" size="mini">复制
                </el-button>
              </template>
            </operatingButton>

            <div>
              <el-table @select="handleSelect" @select-all='selectTableAll' ref="multipleTable" :data="tableData"
                tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange" border
                v-loading="loadingTable" @row-click="handleRowClick" :max-height="tableHeight" :height="tableHeight"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}" row-key="goodsId">>
                <el-table-column type="selection" width="50" align="center" />
                <el-table-column type="" width="50" label="序号" align="center" prop="parentIndex" />
                <el-table-column label="商品编码" width="160" align="center" key="goodsNo" prop="goodsNo"
                  :show-overflow-tooltip="true" sortable>
                  <template slot-scope="scope">
                    <el-button @click.native.prevent="handleUpdate(scope.row)" type="text">
                      {{ scope.row.goodsNo }}
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column label="商品名称" align="center" key="goodsName" prop="goodsName" width="160"
                  :show-overflow-tooltip="true" />
                <el-table-column label="条码" width="100" align="center" key="barcode" prop="barcode">
                </el-table-column>
                <el-table-column label="商品类型" align="center" prop="goodsType">
                  <template slot-scope="scope">
                    <dict-tag :options="dict.type.goods_type" :value="scope.row.goodsType" />
                  </template>
                </el-table-column>
                <el-table-column label="商品类别" align="center" key="categoryName" prop="categoryName" width="140"
                  :show-overflow-tooltip="true" />
                <el-table-column label="商品品牌" align="center" key="brandName" prop="brandName"
                  :show-overflow-tooltip="true" />
                <el-table-column label="基本单位" align="center" key="unitName" prop="unitName"
                  :show-overflow-tooltip="true" />
                <el-table-column label="规格" align="center" key="goodsSpec" prop="goodsSpec"
                  :show-overflow-tooltip="true" />
                <el-table-column label="重量" align="center" key="weight" prop="weight" :show-overflow-tooltip="true" />
                <el-table-column label="型号" align="center" key="goodsModel" prop="goodsModel"
                  :show-overflow-tooltip="true" />
                <el-table-column label="商品状态" align="center" prop="status" width="120">
                  <template slot-scope="scope">
                    <dict-tag :options="dict.type.common_status" :value="scope.row.status" />
                  </template>
                </el-table-column>
                <el-table-column label="保质期天数" align="center" key="qualityDays" prop="qualityDays"
                  :show-overflow-tooltip="true" width="100" />
                <el-table-column label="商品进价" align="center" key="purPrice" prop="purPrice"
                  :show-overflow-tooltip="true" />
                <el-table-column label="商品售价" align="center" key="salePrice" prop="salePrice"
                  :show-overflow-tooltip="true" />
                <el-table-column label="税率(%)" align="center" key="taxRate" prop="taxRate"
                  :show-overflow-tooltip="true" />
                <el-table-column label="计价方式" align="center" prop="pricingMode">
                  <template slot-scope="scope">
                    <dict-tag :options="dict.type.pricing_mode" :value="scope.row.pricingMode" />
                  </template>
                </el-table-column>
                <el-table-column label="是否管库存" align="center" prop="isManageStock" width="100">
                  <template slot-scope="scope">
                    <dict-tag :options="dict.type.App_is_manage_stock" :value="scope.row.isManageStock" />
                  </template>
                </el-table-column>
                <el-table-column label="助记码" align="center" key="zjm" prop="zjm" :show-overflow-tooltip="true" />
                <el-table-column label="备注" align="center" key="remark" prop="remark" width="120"
                  :show-overflow-tooltip="true" />
                <el-table-column label="修改人" align="center" key="updateBy" prop="updateBy" width="120"
                  :show-overflow-tooltip="true" />
                <el-table-column label="修改时间" align="center" prop="updateTime" width="155">
                </el-table-column>
                <el-table-column label="创建人" align="center" key="createBy" prop="createBy" width="120"
                  :show-overflow-tooltip="true" />
                <el-table-column label="创建时间" align="center" prop="createTime" width="155">
                </el-table-column>
              </el-table>
              <bottomPage v-model="queryParams" :getList="getList" :idsCom="ids" :totalCom="total"
                @selectAllCom="selectAll" />
            </div>
          </div>
        </template>
      </cardTitleCom>
    </div>

    <c-dialog title="批量修改" :width="400" :showDialog.sync="openBatch">
      <template #content>
        <el-form ref="batchForm" :model="batchForm" label-width="80px">
          <el-row>
            <el-col :span="20">
              <el-form-item label="商品类别" prop="parentId">
                <treeselect style="width: 250px" v-model="batchForm.categoryId"
                  :options="categoryTreeOptionsUnclassified" :show-count="true" placeholder="请选择类别"
                  :normalizer="normalizerCategory" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="商品品牌" prop="brandId">
                <el-select class="inputWidth" v-model="batchForm.brandId" clearable placeholder="请选择商品品牌">
                  <el-option v-for="item in allBrandData" :key="item.brandId" :label="item.brandName"
                    :value="item.brandId" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="基本单位" prop="brandId">
                <el-select class="inputWidth" v-model="batchForm.unitName" clearable placeholder="请选择基本单位">
                  <el-option v-for="item in unitList" :key="item.dictValue" :label="item.dictLabel"
                    :value="item.dictValue" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="税率" prop="taxRate">
                <el-input class="inputWidth" v-model.number="batchForm.taxRate" maxlength="30" placeholder="请输入税率" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="openBatch = false">取 消</el-button>
        <el-button type="primary" @click="submitBatchForm">确 定</el-button>
      </template>
    </c-dialog>

    <c-dialog :title="categoryTitle" :width="500" :showDialog.sync="openCategory">
      <template #content>
        <el-form ref="categoryForm" :model="categoryForm" :rules="categoryRules" label-width="80px"
          v-loading="loadingDia">
          <el-row>
            <el-col :span="20">
              <el-form-item label="上级类别" prop="parentId">
                <treeselect v-model="categoryForm.parentId" :options="categoryTreeOptionsUnclassified"
                  :show-count="true" placeholder="请选择上级类别" :normalizer="normalizerCategory" :open="getTreeselect"
                  @input="changeParentId" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="类别编码" prop="categoryNo">
                <div class="x-fc">
                  <el-input v-show="categoryForm.parentId != 0" v-model="categoryForm.categoryNo1" placeholder="请选择上级类别"
                    disabled />
                  <el-input v-model="categoryForm.categoryNo" placeholder="请输入类别编码" maxlength="13" />
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="类别名称" prop="categoryName">
                <el-input v-model="categoryForm.categoryName" placeholder="请输入类别名称" maxlength="30" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="排序编号" prop="sortNo">
                <el-input v-model.number="categoryForm.sortNo" placeholder="请输入排序编号" maxlength="30" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="零售可见" prop="isPosVisible">
                <el-radio-group v-model="categoryForm.isPosVisible">
                  <el-radio v-for="dict in dict.type.App_is_pos_visible" :key="dict.value"
                    :label="dict.value">{{ dict.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="备注" prop="remark">
                <el-input type="text" v-model="categoryForm.remark" placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancelCategory">取 消</el-button>
        <el-button type="primary" @click="submitCategoryForm">确 定</el-button>
      </template>
    </c-dialog>
    <to-channel :visible.sync="openToChannel" :downloadFile="downloadFile" @getFileUrl="getFileUrl"
      @removeFile="removeFile" @upload="uploadFile"></to-channel>
    <importError :errVisible.sync="errVisible" :errorList="errorList" />
    <!-- 回收站 -->
    <Dialog2 :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {
  listCategoryTree,
  addCategory,
  CategoryDetail,
  updateCategory,
  delCategory,
  getGoodsCategoryAutoNo,
  uploadFileRequest,
} from "@/api/goods/category";
import {
  getGoodsUnitDictData, //单位字典api
  delGoods,
  batchUpdateGoods,
  restoreGoodsInfo,
  listTreePage,
  serviceCopyAPI,
  listGoods
} from "@/api/goods/goods";
import { allBrand } from "@/api/goods/brand";
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import categoryTreeCard from "@/views/components/categoryTreeCard"; //选择类别
import bottomPage from "@/views/components/bottomPage"; //底部分页
import seniorSearch from "@/views/components/seniorSearch"; //高级搜索
import operatingButton from "@/views/components/operatingButton"; //操作按钮
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import ToChannel from "@/components/dialogTemplate/toChannel/index.vue";
import importError from "@/views/components/importError"; //错误信息
import CDialog from "@/components/dialogTemplate/dialog/index.vue"; //弹窗组件
import Dialog2 from "@/components/Dialog"; //回收站
export default {
  name: "Info",
  dicts: [
    "App_is_manage_stock",
    "pricing_mode",
    "goods_type",
    "common_status",
    "App_is_pos_visible",
  ],
  components: {
    Treeselect,
    cardTitleCom,
    categoryTreeCard,
    bottomPage,
    seniorSearch,
    operatingButton,
    SelectLocal,
    ToChannel,
    importError,
    CDialog,
    Dialog2,
  },
  data() {
    return {
      idCopys: [],
      selectionList: [],
      unitList: [], //基本单位
      dialogOptions: {
        type: "ShopInfo",
        show: false,
        title: "回收站",
        width: "75vw",
        data: {
          getListApi: listGoods,
          restoreListApi: restoreGoodsInfo,
          id: "goodsId",
          search: [
            {
              type: "filters",
              isRestore: true,
              model: "",
              filters: [
                { filter: "query", label: "全部" },
                { filter: "goodsNos", label: "商品编码" },
                { filter: "goodsNames", label: "商品名称" },
                { filter: "barcodes", label: "商品条码" },
              ],
            },
            {
              type: "button",
              tip: "查询",
              btnType: "primary",
              click: "search",
              isRestore: true,
            },
          ],
          columns: [
            {
              prop: "goodsNo",
              label: "商品编码",
              minWidth: 180,
            },
            {
              prop: "goodsName",
              label: "商品名称",
              minWidth: 180,
            },
            {
              prop: "barcode",
              label: "条码",
              minWidth: 120,
            },
            {
              prop: "goodsTypeName",
              label: "商品类型",
              minWidth: 100,
            },
            {
              prop: "categoryName",
              label: "商品类别",
              minWidth: 120,
            },
            {
              prop: "brandName",
              label: "商品品牌",
              minWidth: 120,
            },
            {
              prop: "unitName",
              label: "基本单位",
              minWidth: 120,
            },
            {
              prop: "goodsSpec",
              label: "规格",
              minWidth: 120,
            },
            {
              prop: "weight",
              label: "重量",
              minWidth: 120,
            },
            {
              prop: "goodsModel",
              label: "型号",
              minWidth: 120,
            },
            {
              prop: "statusName",
              label: "商品状态",
              minWidth: 120,
            },
            {
              prop: "qualityDays",
              label: "保质期天数",
              minWidth: 120,
            },
            {
              prop: "purPrice",
              label: "商品进价",
              minWidth: 120,
            },
            {
              prop: "salePrice",
              label: "商品售价",
              minWidth: 120,
            },
            {
              prop: "taxRate",
              label: "税率(%)",
              minWidth: 120,
            },
            {
              prop: "pricingModeName",
              label: "计价方式",
              minWidth: 120,
            },
            {
              prop: "isManageStock",
              label: "是否管库存",
              minWidth: 120,
            },
            {
              prop: "zjm",
              label: "助记码",
              minWidth: 120,
            },
            {
              prop: "remark",
              label: "备注",
              minWidth: 120,
            },
            {
              prop: "updateBy",
              label: "修改人",
              minWidth: 120,
            },
            {
              prop: "updateTime",
              label: "修改时间",
              minWidth: 155,
            },
            {
              prop: "createBy",
              label: "创建人",
              minWidth: 120,
            },
            {
              prop: "createTime",
              label: "创建时间",
              minWidth: 155,
            },
          ],
        },
      },
      //错误信息
      errVisible: false,
      //错误信息
      errorList: [],
      downloadFile: {
        // 下载模板
        fileName: "商品导入模板.xlsx", // 文件名
        downloadUrl:
          "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-11-27/隼云商品导入示范文件.xlsx", // 下载地址
      },
      openToChannel: false, // 打开导入弹框
      isParentIdDetail: false, //类别节流
      selectTreeNo: undefined,
      selectTreeId: undefined,
      showHigh: false, //是否显示高级搜索
      loadingDia: false,
      //搜索框搜索的条件
      dropDownList: [
        { parameter: "query", name: "全部" },
        { parameter: "goodsNos", name: "商品编码" },
        { parameter: "goodsNames", name: "商品名称" },
        { parameter: "barcodes", name: "商品条码" },
      ],
      loadingTable: false, //表格加载
      // 遮罩层
      loading: false,
      // 表格选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 弹出层标题
      title: "",
      categoryTreeOptions: [],
      // 分类树选项  没有未分类
      categoryTreeOptionsUnclassified: [],
      //商品表格数据
      tableData: [],
      //选择多规格分类id
      categoryId: undefined,
      //商品类别对话框开关
      openCategory: false,
      //商品类别对话框标题
      categoryTitle: undefined,
      //商品品牌数据
      allBrandData: undefined,
      //批量修改开关
      openBatch: false,
      //批量修改表单
      batchForm: {
        goodsIds: [],
        brandId: undefined,
        categoryId: undefined,
        taxRate: undefined,
      },
      // 商品类别表单参数
      categoryForm: {
        categoryNo: undefined,
        categoryName: undefined,
        parentId: undefined,
        isPosVisible: "1",
        sortNo: undefined,
        remark: undefined,
        categoryId: undefined,
      },
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        categoryId: undefined,
        barcode: undefined,
        goodsName: undefined,
        status: undefined,
        pricingMode: undefined,
        isManageStock: undefined,
        qualityDays: undefined,
        queryCode: undefined,
        goodsTypes: [],
      },
      //选择多规格查询参数
      queryMultiSpec: {
        pageNum: 1,
        pageSize: 15,
        categoryId: undefined,
      },
      // 商品类别表单校验
      categoryRules: {
        parentId: [
          { required: true, message: "上级类别不能为空", trigger: "blur" },
        ],
        categoryName: [
          { required: true, message: "类别名称不能为空", trigger: "blur" },
        ],
        categoryNo: [
          { required: true, message: "类别编号不能为空", trigger: "blur" },
        ],
        sortNo: [{ type: "number", message: "排序需为数字" }],
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40,
    };
  },
  async created() {
    await this.getTreeselect();
    await this.getBrand();
    //基本单位
    getGoodsUnitDictData().then((response) => {
      this.unitList = response.data;
    });
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 140;
    },
    boxHeight() {
      return this.vivwH - 10;
    },
    boxInternalHeight() {
      return this.vivwH - 83;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    //复制
    async getCopyOrder() {
      if (this.idCopys.length != 1) {
        return this.$message.error("请选择一个主商品进行复制！");
      }
      await serviceCopyAPI(this.idCopys)
      await this.getList()
      this.$message.success("复制成功!")
    },
    //还原刷新页面
    async handleEvent() {
      this.loading = true;
      await this.getList();
      await this.getTreeselect();
      this.loading = false;
    },
    uploadFile(fromData) {
      uploadFileRequest(fromData)
        .then((res) => {
          this.$message.success("上传成功");
          this.openToChannel = false;
          this.getList();
        })
        .catch((error) => {
          console.log("error", error);
          this.errorList = error.data.errorList.map((item) => {
            return { errorInfo: item };
          });
          this.errVisible = true;
        });
    },
    //导入
    getFileUrl(file) {
      console.log("上传成功回调", file);
    },
    // 删除文件
    removeFile(url) { },
    async changeParentId(newVal) {
      if (this.isParentIdDetail) return;
      if (newVal || newVal == 0) {
        this.loadingDia = true;
        const { data } = await getGoodsCategoryAutoNo(newVal);
        this.categoryForm.categoryNo1 = data.parentNo;
        this.categoryForm.categoryNo = data.autoNo;
        this.loadingDia = false;
      } else {
        this.categoryForm.categoryNo1 = "";
        this.categoryForm.categoryNo = "";
      }
    },
    handleResize() {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
    },
    //是否显示高级搜索
    getIsShowHigh(value) {
      this.showHigh = value;
      this.$nextTick(() => {
        this.handleResize();
      });
    },
    //点击类别树
    getTreeNodeId(value) {
      this.selectTreeId = value?.categoryId;
      this.selectTreeNo = value?.categoryNo;
      this.queryParams.categoryId = value.categoryId;
      this.getList();
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true;
        const res = await listTreePage({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total,
        });
        res.rows = res.rows.map((item, index) => {
          item.parentIndex = index + 1
          if (item.children) {
            item.children.forEach((x, inD) => {
              x.parentIndex = item.parentIndex + '.' + (inD + 1)
            })
          }
          return item
        })
        //重新渲染表格
        this.tableData = res.rows;
        //表格多选
        this.tableData.map((row) => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value);
          });
        });
        this.loadingTable = false;
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection();
        });
        this.getList();
      }
    },
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row);
      this.handleSelect(this.selectionList, row)
    },

    //新增Tree商品类别
    appendTree(data) {
      this.isParentIdDetail = true;
      this.resetForm("categoryForm");
      this.categoryForm.parentId = data.id;
      this.isParentIdDetail = false;
      this.changeParentId(this.categoryForm.parentId);
      this.categoryTitle = "新增类别";
      this.openCategory = true;
    },
    //编辑Tree商品类别
    editTree(data) {
      this.openCategory = true;
      this.loadingDia = true;
      this.isParentIdDetail = true;
      const CategoryId = data.id;
      CategoryDetail(CategoryId)
        .then((response) => {
          this.categoryForm.parentId = response.data.parentId;
          this.categoryForm.categoryId = response.data.categoryId;
          this.categoryForm.categoryNo1 = response.data.parentNo;
          this.categoryForm.categoryNo = response.data.categoryNo;
          this.categoryForm.categoryName = response.data.categoryName;
          this.categoryForm.isPosVisible = response.data.isPosVisible;
          this.categoryForm.sortNo = response.data.sortNo;
          this.categoryForm.remark = response.data.remark;
          this.categoryTitle = "修改类别";
          this.loadingDia = false;
        })
        .then((res) => {
          this.isParentIdDetail = false;
        })
        .catch((res) => {
          this.isParentIdDetail = false;
        });
    },

    //删除Tree商品类别
    removeTree({ node, data }) {
      const CategoryIds = [data.id];
      this.$modal
        .confirm('是否确认删除分类名称为"' + data.label + '"的数据项？')
        .then(function () {
          return delCategory(CategoryIds);
        })
        .then(() => {
          this.getTreeselect();
          this.$modal.msgSuccess("删除成功");
        })
        .catch(() => { });
    },
    /** 商品类别提交按钮 */
    submitCategoryForm: function () {
      this.$refs["categoryForm"].validate((valid) => {
        if (valid) {
          if (this.categoryForm.categoryId == this.categoryForm.parentId) {
            this.$message.error("父类不能和子类相同");
            return;
          }
          let obj = JSON.parse(JSON.stringify(this.categoryForm));
          obj.categoryNo = (obj.categoryNo1 || "") + (obj.categoryNo || "");
          if (obj.categoryId != undefined) {
            updateCategory(obj)
              .then((response) => {
                this.loadingDia = false;
                this.$modal.msgSuccess("修改成功");
                this.openCategory = false;
                this.getTreeselect();
              })
              .catch((error) => {
                console.log("Error:", error);
                this.loadingDia = false;
              });
          } else {
            addCategory(obj)
              .then((response) => {
                this.loadingDia = false;
                this.$modal.msgSuccess("新增成功");
                this.openCategory = false;
                this.getTreeselect();
              })
              .catch((error) => {
                console.log("Error:", error);
                this.loadingDia = false;
              });
          }
        }
      });
    },
    // 商品类别取消按钮
    cancelCategory() {
      this.openCategory = false;
      this.categoryForm = {
        categoryNo: undefined,
        categoryName: undefined,
        parentId: undefined,
        isPosVisible: "1",
        sortNo: undefined,
        remark: undefined,
        categoryId: undefined,
      };
      this.resetForm("categoryForm");
    },
    //下拉树结构数据转换
    normalizer(node) {
      return {
        id: node.classId,
        label: node.label,
        children: node.children,
      };
    },
    //商品类别treeSelect结构属性转换
    normalizerCategory(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    //获取商品品牌
    getBrand() {
      allBrand().then((response) => {
        this.allBrandData = response.data;
      });
    },
    /** 查询商品列表 */
    async getList(val) {
      console.log("sssssssssss");
      try {
        this.loadingTable = true;
        const res = await listTreePage(this.queryParams);
        res.rows = res.rows.map((item, index) => {
          item.parentIndex = index + 1
          if (item.children) {
            item.children.forEach((x, inD) => {
              x.parentIndex = item.parentIndex + '.' + (inD + 1)
            })
          }
          return item
        })
        this.tableData = res?.rows || [];
        this.total = res?.total || 0;
      } catch (error) {
      } finally {
        this.loadingTable = false;
      }
    },
    /** 查询商品类别下拉树结构 */
    getTreeselect() {
      listCategoryTree().then((response) => {
        //有未分类
        this.categoryTreeOptions = JSON.parse(JSON.stringify(response.data));
        //没未分类
        if (response.data.length > 0) {
          response.data[0].children = response.data[0].children?.filter(
            (v) => v.number != "00"
          );
        }
        this.categoryTreeOptionsUnclassified = response.data;
      });
    },

    // 多选框选中数据
    handleSelectionChange(selection) {
      this.selectionList = selection
      this.idCopys = selection.filter(item => !item.sourceGoodsId).map(item => item.goodsId).filter(item => item != undefined)
      this.ids = selection.map((item) => item.goodsId);
      this.multiple = !selection.length;
    },
    handleSelect(selection, row) {
      console.log('selection', selection);
      if (row.children.length > 0) {
        // 这个是父节点取消勾选的情况，将所有子节点全部取消勾选
        for (let i = 0; i < row.children.length; i++) {
          const child = row.children[i];
          // 如果没有被勾选就勾选
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(child, selection.includes(row));
          })
        }
      }
    },
    selectTableAll(selection) {
      // 根据勾选状态判断是否勾选或取消勾选所有子节点
      this.tableData.forEach((row) => {
        if (!row.children || row.children.length === 0) {
          return;
        }

        // 取消勾选所有子节点，不管之前是否有取消勾选过的子节点
        row.children.forEach((child) => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(child, false);
          })
        });
        if (selection.includes(row)) {
          // 勾选父节点时，勾选所有子节点
          row.children.forEach((child) => {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(child);
            })
          });
        }
      });
    },
    /** 新增按钮操作 */
    handleAdd() {
      if (!this.selectTreeId) {
        this.$message.error("请先选择除全部类别以外的其他类别");
        return;
      }
      //点击tree获取的id赋值给商品类别选择项
      // let categoryId = this.categoryTreeOptions[0].id
      const timestampId = Date.now();
      //跳转商品明细页
      this.$router.push({
        path: "/system/goods/info/infoAdd",
        query: {
          type: "add",
          categoryId: this.selectTreeId,
          categoryNumber: this.selectTreeNo,
          timestampId,
        },
      });
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      //获取商品id
      const goodsId = row.goodsId;
      //跳转商品明细页
      this.$router.push({
        path: "/system/goods/info/infoDetail",
        query: {
          type: "update",
          goodsId: goodsId,
        },
      });
    },
    //点击批量修改按钮
    async batchEditor() {
      this.batchForm = {
        goodsIds: [],
        brandId: undefined,
        categoryId: undefined,
        taxRate: undefined,
      };
      //   this.getTreeselect()

      this.openBatch = true;
    },
    //批量修改确认按钮
    async submitBatchForm() {
      if (this.batchForm.categoryId==0)return this.$message.error("不能选择全部分类");
      this.batchForm.goodsIds = this.ids;
      batchUpdateGoods(this.batchForm).then((response) => {
        this.$modal.msgSuccess("批量修改商品资料成功");
        this.openBatch = false;
        this.getList();
      });
    },
    /** 删除按钮操作 */
    handleDelete() {
      const goodsIds = this.ids;
      this.$confirm(`确定删除所选的<span style="color: #1890ff;"> ${goodsIds.length} </span>个商品吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning',
      })
        .then(function () {
          return delGoods(goodsIds);
        })
        .then(() => {
          this.getList();
          this.$modal.msgSuccess("删除成功");
        })
        .catch(() => { });
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        "/api/system/goods/info/exportGoodsInfo",
        {
          ...this.queryParams,
        },
        `商品资料导出.xlsx`
      );
    },
  },
};
</script>
<style lang="scss" scoped>
//整体盒子
.wrap {
  width: 100%;
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 右边
  .rightBox {
    height: calc(100vh - 98px);
    width: calc(100% - 354px); //商品搜索条件
    .headGoodsSearch {
      width: 100%;
      margin-bottom: 6px;
      // 搜索
      .navSearch {
        padding: 10px 10px 10px 20px;
      }
    }
    //商品表格卡片
    .headGoodsTable {
      height: calc(100% - 94px);
      margin-bottom: 0;
      flex: 1;
      .goodsMessage {
        margin: 6px 10px 0 10px;
      }
    }
  }
}
</style>
