var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c(
        "div",
        { staticClass: "btnTOP" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.submitForm(true)
                },
              },
            },
            [_vm._v("保存 ")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "print",
                  rawName: "v-print",
                  value: "#printMe",
                  expression: "'#printMe'",
                },
              ],
              attrs: { type: "primary", size: "mini" },
            },
            [_vm._v(" 测试打印 ")]
          ),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.getQuit } },
            [_vm._v("退出")]
          ),
        ],
        1
      ),
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "基本设置" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "padd15 basicSettings" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: { model: _vm.labelTemplateForm, rules: _vm.rules },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "模板名称",
                          "label-width": "80px",
                          prop: "lableTemplateName",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          model: {
                            value: _vm.labelTemplateForm.lableTemplateName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.labelTemplateForm,
                                "lableTemplateName",
                                $$v
                              )
                            },
                            expression: "labelTemplateForm.lableTemplateName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "纸张尺寸",
                              "label-width": "80px",
                              prop: "width",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "x-f marR20" },
                              [
                                _c("span", { staticClass: "fontS14 marR10" }, [
                                  _vm._v("宽"),
                                ]),
                                _c("el-input", {
                                  staticClass: "marR10",
                                  attrs: { type: "number" },
                                  model: {
                                    value: _vm.labelTemplateForm.width,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.labelTemplateForm,
                                        "width",
                                        $$v
                                      )
                                    },
                                    expression: "labelTemplateForm.width",
                                  },
                                }),
                                _c("span", { staticClass: "fontS14 marR10" }, [
                                  _vm._v("mm"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c("el-form-item", { attrs: { prop: "height" } }, [
                          _c(
                            "div",
                            { staticClass: "x-f" },
                            [
                              _c("span", { staticClass: "fontS14 marR10" }, [
                                _vm._v("高"),
                              ]),
                              _c("el-input", {
                                staticClass: "marR10",
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.labelTemplateForm.height,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.labelTemplateForm,
                                      "height",
                                      $$v
                                    )
                                  },
                                  expression: "labelTemplateForm.height",
                                },
                              }),
                              _c("span", { staticClass: "fontS14" }, [
                                _vm._v("mm"),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-x" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "列数",
                              "label-width": "80px",
                              prop: "colNum",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c("el-input", {
                                  staticClass: "marR10",
                                  attrs: {
                                    type: "number",
                                    oninput:
                                      "if (value > 2) value = 2; value=value.replace(/^0|[^0-9]/g,'')",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.colNumInput($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.labelTemplateForm.colNum,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.labelTemplateForm,
                                        "colNum",
                                        $$v
                                      )
                                    },
                                    expression: "labelTemplateForm.colNum",
                                  },
                                }),
                                _c("span", { staticClass: "fontS14 marR10" }, [
                                  _vm._v("列"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.labelTemplateForm.colNum == 2,
                                expression: "labelTemplateForm.colNum == 2",
                              },
                            ],
                            attrs: {
                              label: "列距",
                              prop: "colMargin",
                              "label-width": "80px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "x-x",
                                staticStyle: { "white-space": "nowrap" },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "marR10",
                                  attrs: {
                                    type: "number",
                                    oninput:
                                      "if (value > 10) value = 10; value=value.replace(/^0|[^0-9]/g,'')",
                                  },
                                  model: {
                                    value: _vm.labelTemplateForm.colMargin,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.labelTemplateForm,
                                        "colMargin",
                                        $$v
                                      )
                                    },
                                    expression: "labelTemplateForm.colMargin",
                                  },
                                }),
                                _c("span", { staticClass: "fontS14 marR10" }, [
                                  _vm._v("mm"),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "fontS14 annotateGrey" },
                                  [_vm._v("（一般标签之间列距为2mm）")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "背景图片", "label-width": "80px" } },
                      [
                        _c("accessory-upload", {
                          attrs: {
                            listType: "picture-card",
                            limit: 1,
                            title: "",
                          },
                          on: {
                            getFileItems: _vm.getFileItemsData,
                            delFileItems: _vm.delFileItems,
                          },
                        }),
                        _c("div", { staticClass: "y-f-j" }, [
                          _c("span", { staticClass: "fontS14" }, [
                            _vm._v(
                              " · 背景图不会被打印，仅用于辅助调整打印项位置。 "
                            ),
                          ]),
                          _c("span", { staticClass: "fontS14" }, [
                            _vm._v(
                              " · 建议图片尺寸比例与纸张尺寸比例一致，单个图片不超过2M。 "
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "模板设置" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c("div", { staticClass: "padd15 x-x" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    "overflow-x": "auto",
                    "white-space": "nowrap",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      ref: "parent",
                      staticClass: "defaultBgImg print-content",
                      style: {
                        "background-image":
                          "url(" + _vm.labelTemplateForm.backgroundUrl + ")",
                        height: _vm.labelTemplateForm.height + "mm",
                        width: _vm.labelTemplateForm.width + "mm",
                      },
                      attrs: { id: "printMe" },
                    },
                    _vm._l(
                      _vm.labelTemplateForm.lableTemplateContent.labelList,
                      function (item, index) {
                        return _c(
                          "vue-draggable-resizable",
                          {
                            key: index,
                            ref: "draggableResizable",
                            refInFor: true,
                            attrs: {
                              w: "auto",
                              h: "auto",
                              x: item.x,
                              y: item.y,
                              resizable: false,
                              config: { snap: false },
                              parent: true,
                            },
                            on: {
                              dragging: _vm.onDrag,
                              dragstop: _vm.handleDragStop,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "mouseInStyle",
                                class: {
                                  activeBorder: index === _vm.activeIndex,
                                },
                                staticStyle: {
                                  border: "1px solid transparent",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleItemClick(item, index)
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          item.tagType != "goodsBarcode" &&
                                          item.tagType != "customText" &&
                                          item.tagType != "qrCode" &&
                                          item.tagType != "nutrient",
                                        expression:
                                          "\n                    item.tagType != 'goodsBarcode' &&\n                    item.tagType != 'customText' &&\n                    item.tagType != 'qrCode' &&\n                    item.tagType != 'nutrient'\n                  ",
                                      },
                                    ],
                                    style: {
                                      fontSize: item.fontSize + "px",
                                      fontWeight: item.bold ? "bold" : "normal",
                                      textDecoration: item.underline
                                        ? "underline"
                                        : "none",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.printContent))]
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.tagType == "nutrient",
                                        expression:
                                          "item.tagType == 'nutrient'",
                                      },
                                    ],
                                    staticClass: "y-c",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        style: {
                                          fontSize: item.fontSize + "px",
                                          fontWeight: item.bold
                                            ? "bold"
                                            : "normal",
                                          textDecoration: item.underline
                                            ? "underline"
                                            : "none",
                                        },
                                      },
                                      [_vm._v(_vm._s(item.printContent))]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "nutrient-table" },
                                      [
                                        _c(
                                          "table",
                                          {
                                            style: {
                                              fontSize: item.fontSize + "px",
                                              fontWeight: item.bold
                                                ? "bold"
                                                : "normal",
                                              textDecoration: item.underline
                                                ? "underline"
                                                : "none",
                                            },
                                          },
                                          [
                                            _c("thead", [
                                              _c("tr", [
                                                _c("th", [
                                                  _vm._v(
                                                    _vm._s(item.labelName1)
                                                  ),
                                                ]),
                                                _c("th", [
                                                  _vm._v(
                                                    _vm._s(item.labelName2)
                                                  ),
                                                ]),
                                                _c("th", [
                                                  _vm._v(
                                                    _vm._s(item.labelName3)
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.nutrients,
                                                function (nutrient, index) {
                                                  return _c(
                                                    "tr",
                                                    { key: index },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            nutrient.nutrientName
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            nutrient.nutrientQty
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            nutrient.nutrientUnit
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.tagType == "goodsBarcode",
                                        expression:
                                          "item.tagType == 'goodsBarcode'",
                                      },
                                    ],
                                  },
                                  [
                                    _c("JsBarcode", {
                                      attrs: {
                                        value: "6901028071069",
                                        displayValue: item.barcodeType != 1,
                                        width: item.barcodeWidth,
                                        height: item.barcodeHeight,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.tagType == "customText",
                                        expression:
                                          "item.tagType == 'customText'",
                                      },
                                    ],
                                    style: {
                                      fontSize: item.fontSize + "px",
                                      fontWeight: item.bold ? "bold" : "normal",
                                      textDecoration: item.underline
                                        ? "underline"
                                        : "none",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.customText) + " ")]
                                ),
                                _c("el-image", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.tagType == "qrCode",
                                      expression: "item.tagType == 'qrCode'",
                                    },
                                  ],
                                  style: {
                                    width: item.imageWidth + "mm",
                                    height: item.imageHeight + "mm",
                                  },
                                  attrs: {
                                    src: item.qrCodeUrl
                                      ? item.qrCodeUrl
                                      : "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-05-22/8b326689866a48529f05bd1cfc50277b.png",
                                  },
                                }),
                                index === _vm.activeIndex
                                  ? _c("i", {
                                      staticClass: "el-icon-error activeClear",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteRow(
                                            index,
                                            _vm.labelTemplateForm
                                              .lableTemplateContent.labelList
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        )
                      }
                    ),
                    1
                  ),
                  _c("div", { staticClass: "printDesign" }, [
                    _c("div", { staticClass: "marB15" }, [
                      _c("p", [_vm._v("基础组件")]),
                      _c(
                        "div",
                        { staticClass: "x-w" },
                        _vm._l(_vm.baseComponentList, function (item, index) {
                          return _c("div", { key: index }, [
                            _c(
                              "div",
                              {
                                staticClass: "buttonCom cursorP",
                                on: {
                                  click: function ($event) {
                                    return _vm.clickComButton(item)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            ),
                          ])
                        }),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "marB15" }, [
                      _c("p", [_vm._v("价格信息")]),
                      _c(
                        "div",
                        { staticClass: "x-w" },
                        _vm._l(_vm.priceList, function (item, index) {
                          return _c("div", { key: index }, [
                            _c(
                              "div",
                              {
                                staticClass: "buttonCom cursorP",
                                on: {
                                  click: function ($event) {
                                    return _vm.clickComButton(item)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            ),
                          ])
                        }),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "marB15" }, [
                      _c("p", [_vm._v("条码和编码")]),
                      _c(
                        "div",
                        { staticClass: "x-w" },
                        _vm._l(_vm.barcodeEncodingList, function (item, index) {
                          return _c("div", { key: index }, [
                            _c(
                              "div",
                              {
                                staticClass: "buttonCom cursorP",
                                on: {
                                  click: function ($event) {
                                    return _vm.clickComButton(item)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            ),
                          ])
                        }),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "marB15" }, [
                      _c("p", [_vm._v("其他信息")]),
                      _c(
                        "div",
                        { staticClass: "x-w" },
                        _vm._l(_vm.otherList, function (item, index) {
                          return _c("div", { key: index }, [
                            _c(
                              "div",
                              {
                                staticClass: "buttonCom cursorP",
                                on: {
                                  click: function ($event) {
                                    return _vm.clickComButton(item, index)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            ),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm.labelTemplateForm.lableTemplateContent.labelList.length > 0 &&
              _vm.activeIndex > -1
                ? _c("div", { staticClass: "designEditor" }, [
                    _c("div", { staticClass: "designEditorTitle" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.labelTemplateForm.lableTemplateContent
                              .labelList[_vm.activeIndex].name
                          )
                        ),
                      ]),
                      _c("i", {
                        staticClass: "el-icon-delete",
                        on: {
                          click: function ($event) {
                            return _vm.deleteRow(
                              _vm.activeIndex,
                              _vm.labelTemplateForm.lableTemplateContent
                                .labelList
                            )
                          },
                        },
                      }),
                    ]),
                    _c("div", [
                      _c("div", [
                        _c("div", { staticClass: "controlGroup x-f" }, [
                          _c("div", { staticClass: "controlGroupLabel" }, [
                            _c("span", { staticClass: "asterisk" }, [
                              _vm._v("*"),
                            ]),
                            _c("span", [_vm._v("坐标：")]),
                          ]),
                          _c("div", { staticClass: "controls" }, [
                            _c(
                              "div",
                              { staticClass: "wrapper" },
                              [
                                _c("span", { staticClass: "fontS14 marR8" }, [
                                  _vm._v("横坐标"),
                                ]),
                                _c("el-input-number", {
                                  attrs: {
                                    "controls-position": "right",
                                    min: 0,
                                    max: 9999,
                                  },
                                  model: {
                                    value:
                                      _vm.labelTemplateForm.lableTemplateContent
                                        .labelList[_vm.activeIndex].x,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.labelTemplateForm
                                          .lableTemplateContent.labelList[
                                          _vm.activeIndex
                                        ],
                                        "x",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n                        labelTemplateForm.lableTemplateContent.labelList[\n                          activeIndex\n                        ].x\n                      ",
                                  },
                                }),
                                _c("span", { staticClass: "fontS14 marR8" }, [
                                  _vm._v("纵坐标"),
                                ]),
                                _c("el-input-number", {
                                  attrs: {
                                    "controls-position": "right",
                                    min: 0,
                                    max: 9999,
                                  },
                                  model: {
                                    value:
                                      _vm.labelTemplateForm.lableTemplateContent
                                        .labelList[_vm.activeIndex].y,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.labelTemplateForm
                                          .lableTemplateContent.labelList[
                                          _vm.activeIndex
                                        ],
                                        "y",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n                        labelTemplateForm.lableTemplateContent.labelList[\n                          activeIndex\n                        ].y\n                      ",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.labelTemplateForm.lableTemplateContent
                                    .labelList[_vm.activeIndex].tagType ==
                                  "customText",
                                expression:
                                  "\n                  labelTemplateForm.lableTemplateContent.labelList[\n                    activeIndex\n                  ].tagType == 'customText'\n                ",
                              },
                            ],
                            staticClass: "controlGroup x-x",
                          },
                          [
                            _c("div", { staticClass: "controlGroupLabel" }, [
                              _c("span", { staticClass: "asterisk" }, [
                                _vm._v("*"),
                              ]),
                              _c("span", [_vm._v("文本内容：")]),
                            ]),
                            _c("div", { staticClass: "controls" }, [
                              _c(
                                "div",
                                { staticClass: "wrapper" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 3,
                                      maxlength: "100",
                                      "show-word-limit": "",
                                      placeholder: "请输入内容",
                                    },
                                    model: {
                                      value:
                                        _vm.labelTemplateForm
                                          .lableTemplateContent.labelList[
                                          _vm.activeIndex
                                        ].customText,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.labelTemplateForm
                                            .lableTemplateContent.labelList[
                                            _vm.activeIndex
                                          ],
                                          "customText",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "\n                        labelTemplateForm.lableTemplateContent.labelList[\n                          activeIndex\n                        ].customText\n                      ",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.shouldShowControlGroup,
                                expression: "shouldShowControlGroup",
                              },
                            ],
                            staticClass: "controlGroup x-x",
                          },
                          [
                            _c("div", { staticClass: "controlGroupLabel" }, [
                              _c("span", [_vm._v("打印内容：")]),
                            ]),
                            _c("div", { staticClass: "controls" }, [
                              _c(
                                "div",
                                { staticClass: "wrapper" },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value:
                                          _vm.labelTemplateForm
                                            .lableTemplateContent.labelList[
                                            _vm.activeIndex
                                          ].printContentType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.labelTemplateForm
                                              .lableTemplateContent.labelList[
                                              _vm.activeIndex
                                            ],
                                            "printContentType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                        labelTemplateForm.lableTemplateContent.labelList[\n                          activeIndex\n                        ].printContentType\n                      ",
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "y-start" }, [
                                        _c(
                                          "div",
                                          { staticClass: "marB10" },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 1 } },
                                              [_vm._v("打印标题和内容")]
                                            ),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content:
                                                    "打印出来的内容包含标题和内容两部分，例如：“商品名称：火龙果",
                                                  placement: "top",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "iconfont icon-wenhao annotateGrey",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "x-f printContent marB10",
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "fontS14 title" },
                                              [_vm._v("标题显示：")]
                                            ),
                                            _c("el-input", {
                                              attrs: {
                                                disabled:
                                                  _vm.labelTemplateForm
                                                    .lableTemplateContent
                                                    .labelList[_vm.activeIndex]
                                                    .printContentType == 2,
                                              },
                                              model: {
                                                value:
                                                  _vm.labelTemplateForm
                                                    .lableTemplateContent
                                                    .labelList[_vm.activeIndex]
                                                    .printContent,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.labelTemplateForm
                                                      .lableTemplateContent
                                                      .labelList[
                                                      _vm.activeIndex
                                                    ],
                                                    "printContent",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                              labelTemplateForm.lableTemplateContent\n                                .labelList[activeIndex].printContent\n                            ",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 2 } },
                                              [_vm._v("仅打印内容")]
                                            ),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content:
                                                    "如“商品名称：火龙果”，仅打印“火龙果”",
                                                  placement: "top",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "iconfont icon-wenhao annotateGrey",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.labelTemplateForm.lableTemplateContent
                                    .labelList[_vm.activeIndex].tagType ==
                                  "nutrient",
                                expression:
                                  "\n                  labelTemplateForm.lableTemplateContent.labelList[\n                    activeIndex\n                  ].tagType == 'nutrient' ",
                              },
                            ],
                          },
                          [
                            _c("div", { staticClass: "controlGroup x-x" }, [
                              _c("div", { staticClass: "controlGroupLabel" }, [
                                _c("span", { staticClass: "asterisk" }, [
                                  _vm._v("*"),
                                ]),
                                _c("span", [_vm._v("标题1：")]),
                              ]),
                              _c("div", { staticClass: "controls" }, [
                                _c(
                                  "div",
                                  { staticClass: "wrapper" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "mini",
                                        "show-word-limit": "",
                                        placeholder: "请输入内容",
                                      },
                                      model: {
                                        value:
                                          _vm.labelTemplateForm
                                            .lableTemplateContent.labelList[
                                            _vm.activeIndex
                                          ].labelName1,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.labelTemplateForm
                                              .lableTemplateContent.labelList[
                                              _vm.activeIndex
                                            ],
                                            "labelName1",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                        labelTemplateForm.lableTemplateContent.labelList[\n                          activeIndex\n                        ].labelName1\n                      ",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "controlGroup x-x" }, [
                              _c("div", { staticClass: "controlGroupLabel" }, [
                                _c("span", { staticClass: "asterisk" }, [
                                  _vm._v("*"),
                                ]),
                                _c("span", [_vm._v("标题2：")]),
                              ]),
                              _c("div", { staticClass: "controls" }, [
                                _c(
                                  "div",
                                  { staticClass: "wrapper" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "mini",
                                        "show-word-limit": "",
                                        placeholder: "请输入内容",
                                      },
                                      model: {
                                        value:
                                          _vm.labelTemplateForm
                                            .lableTemplateContent.labelList[
                                            _vm.activeIndex
                                          ].labelName2,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.labelTemplateForm
                                              .lableTemplateContent.labelList[
                                              _vm.activeIndex
                                            ],
                                            "labelName2",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                        labelTemplateForm.lableTemplateContent.labelList[\n                          activeIndex\n                        ].labelName2\n                      ",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "controlGroup x-x" }, [
                              _c("div", { staticClass: "controlGroupLabel" }, [
                                _c("span", { staticClass: "asterisk" }, [
                                  _vm._v("*"),
                                ]),
                                _c("span", [_vm._v("标题3：")]),
                              ]),
                              _c("div", { staticClass: "controls" }, [
                                _c(
                                  "div",
                                  { staticClass: "wrapper" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "mini",
                                        "show-word-limit": "",
                                        placeholder: "请输入内容",
                                      },
                                      model: {
                                        value:
                                          _vm.labelTemplateForm
                                            .lableTemplateContent.labelList[
                                            _vm.activeIndex
                                          ].labelName3,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.labelTemplateForm
                                              .lableTemplateContent.labelList[
                                              _vm.activeIndex
                                            ],
                                            "labelName3",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                        labelTemplateForm.lableTemplateContent.labelList[\n                          activeIndex\n                        ].labelName3\n                      ",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.labelTemplateForm.lableTemplateContent
                                    .labelList[_vm.activeIndex].tagType ==
                                    "printDate" ||
                                  _vm.labelTemplateForm.lableTemplateContent
                                    .labelList[_vm.activeIndex].tagType ==
                                    "produceDate" ||
                                  _vm.labelTemplateForm.lableTemplateContent
                                    .labelList[_vm.activeIndex].tagType ==
                                    "expirationDate",
                                expression:
                                  "\n                  labelTemplateForm.lableTemplateContent.labelList[\n                    activeIndex\n                  ].tagType == 'printDate'|| labelTemplateForm.lableTemplateContent.labelList[\n                    activeIndex\n                  ].tagType == 'produceDate' || labelTemplateForm.lableTemplateContent.labelList[\n                    activeIndex\n                  ].tagType == 'expirationDate'\n                ",
                              },
                            ],
                            staticClass: "controlGroup x-x",
                          },
                          [
                            _c("div", { staticClass: "controlGroupLabel" }, [
                              _c("span", [_vm._v("展示样式：")]),
                            ]),
                            _c("div", { staticClass: "controls" }, [
                              _c(
                                "div",
                                { staticClass: "wrapper" },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value:
                                          _vm.labelTemplateForm
                                            .lableTemplateContent.labelList[
                                            _vm.activeIndex
                                          ].printDateType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.labelTemplateForm
                                              .lableTemplateContent.labelList[
                                              _vm.activeIndex
                                            ],
                                            "printDateType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                        labelTemplateForm.lableTemplateContent.labelList[\n                          activeIndex\n                        ].printDateType\n                      ",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "y-start" },
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              staticClass: "marB10",
                                              attrs: { label: 1 },
                                            },
                                            [
                                              _vm._v(
                                                "YYYY年MM月DD日，如：2018年07月12日"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-radio",
                                            {
                                              staticClass: "marB10",
                                              attrs: { label: 2 },
                                            },
                                            [
                                              _vm._v(
                                                "YYYY/MM/DD，如：2018/07/12 "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-radio",
                                            {
                                              staticClass: "marB10",
                                              attrs: { label: 3 },
                                            },
                                            [
                                              _vm._v(
                                                "YYYY年MM年DD日 HH时mm分ss秒，如：2018年07月12日10时24分56秒 "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 4 } },
                                            [
                                              _vm._v(
                                                "YYYY/MM/DD HH:mm:ss，如：2018/07/12 10:24:56 "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.labelTemplateForm.lableTemplateContent
                                    .labelList[_vm.activeIndex].tagType ==
                                  "produceDate",
                                expression:
                                  "\n                  labelTemplateForm.lableTemplateContent.labelList[\n                    activeIndex\n                  ].tagType == 'produceDate'\n                ",
                              },
                            ],
                            staticClass: "controlGroup x-f",
                          },
                          [
                            _c("div", { staticClass: "controlGroupLabel" }, [
                              _c("span", [_vm._v("延后天数：")]),
                            ]),
                            _c("div", { staticClass: "controls" }, [
                              _c("div", { staticClass: "wrapper" }, [
                                _c(
                                  "div",
                                  { staticClass: "fontSizeSelect" },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value:
                                          _vm.labelTemplateForm
                                            .lableTemplateContent.labelList[
                                            _vm.activeIndex
                                          ].delayDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.labelTemplateForm
                                              .lableTemplateContent.labelList[
                                              _vm.activeIndex
                                            ],
                                            "delayDate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "labelTemplateForm.lableTemplateContent.labelList[activeIndex].delayDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.labelTemplateForm.lableTemplateContent
                                    .labelList[_vm.activeIndex].tagType !=
                                    "goodsBarcode" &&
                                  _vm.labelTemplateForm.lableTemplateContent
                                    .labelList[_vm.activeIndex].tagType !=
                                    "qrCode",
                                expression:
                                  "\n                  labelTemplateForm.lableTemplateContent.labelList[\n                    activeIndex\n                  ].tagType != 'goodsBarcode' &&\n                  labelTemplateForm.lableTemplateContent.labelList[\n                    activeIndex\n                  ].tagType != 'qrCode'\n                ",
                              },
                            ],
                            staticClass: "controlGroup x-f",
                          },
                          [
                            _c("div", { staticClass: "controlGroupLabel" }, [
                              _c("span", { staticClass: "asterisk" }, [
                                _vm._v("*"),
                              ]),
                              _c("span", [_vm._v("字体大小：")]),
                            ]),
                            _c("div", { staticClass: "controls" }, [
                              _c("div", { staticClass: "wrapper" }, [
                                _c(
                                  "div",
                                  { staticClass: "fontSizeSelect" },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "字体大小",
                                          filterable: "",
                                        },
                                        model: {
                                          value:
                                            _vm.labelTemplateForm
                                              .lableTemplateContent.labelList[
                                              _vm.activeIndex
                                            ].fontSize,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.labelTemplateForm
                                                .lableTemplateContent.labelList[
                                                _vm.activeIndex
                                              ],
                                              "fontSize",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                          labelTemplateForm.lableTemplateContent.labelList[\n                            activeIndex\n                          ].fontSize\n                        ",
                                        },
                                      },
                                      _vm._l(_vm.fontSizeList, function (item) {
                                        return _c("el-option", {
                                          key: item,
                                          attrs: { label: item, value: item },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.labelTemplateForm.lableTemplateContent
                                    .labelList[_vm.activeIndex].tagType !=
                                    "goodsBarcode" &&
                                  _vm.labelTemplateForm.lableTemplateContent
                                    .labelList[_vm.activeIndex].tagType !=
                                    "qrCode",
                                expression:
                                  "\n                  labelTemplateForm.lableTemplateContent.labelList[\n                    activeIndex\n                  ].tagType != 'goodsBarcode' &&\n                  labelTemplateForm.lableTemplateContent.labelList[\n                    activeIndex\n                  ].tagType != 'qrCode'\n                ",
                              },
                            ],
                            staticClass: "controlGroup x-f",
                          },
                          [
                            _c("div", { staticClass: "controlGroupLabel" }, [
                              _c("span", [_vm._v("文字样式：")]),
                            ]),
                            _c("div", { staticClass: "controls" }, [
                              _c(
                                "div",
                                { staticClass: "wrapper" },
                                [
                                  _c("svg-icon", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          !_vm.labelTemplateForm
                                            .lableTemplateContent.labelList[
                                            _vm.activeIndex
                                          ].bold,
                                        expression:
                                          "\n                        !labelTemplateForm.lableTemplateContent.labelList[\n                          activeIndex\n                        ].bold\n                      ",
                                      },
                                    ],
                                    staticClass: "marR10 cursorP",
                                    attrs: { "icon-class": "bold" },
                                    on: {
                                      click: function ($event) {
                                        _vm.labelTemplateForm.lableTemplateContent.labelList[
                                          _vm.activeIndex
                                        ].bold = true
                                      },
                                    },
                                  }),
                                  _c("svg-icon", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.labelTemplateForm
                                            .lableTemplateContent.labelList[
                                            _vm.activeIndex
                                          ].bold,
                                        expression:
                                          "\n                        labelTemplateForm.lableTemplateContent.labelList[\n                          activeIndex\n                        ].bold\n                      ",
                                      },
                                    ],
                                    staticClass: "marR10 cursorP",
                                    attrs: { "icon-class": "boldSelect" },
                                    on: {
                                      click: function ($event) {
                                        _vm.labelTemplateForm.lableTemplateContent.labelList[
                                          _vm.activeIndex
                                        ].bold = false
                                      },
                                    },
                                  }),
                                  _c("svg-icon", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          !_vm.labelTemplateForm
                                            .lableTemplateContent.labelList[
                                            _vm.activeIndex
                                          ].underline,
                                        expression:
                                          "\n                        !labelTemplateForm.lableTemplateContent.labelList[\n                          activeIndex\n                        ].underline\n                      ",
                                      },
                                    ],
                                    staticClass: "cursorP",
                                    attrs: { "icon-class": "underline" },
                                    on: {
                                      click: function ($event) {
                                        _vm.labelTemplateForm.lableTemplateContent.labelList[
                                          _vm.activeIndex
                                        ].underline = true
                                      },
                                    },
                                  }),
                                  _c("svg-icon", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.labelTemplateForm
                                            .lableTemplateContent.labelList[
                                            _vm.activeIndex
                                          ].underline,
                                        expression:
                                          "\n                        labelTemplateForm.lableTemplateContent.labelList[\n                          activeIndex\n                        ].underline\n                      ",
                                      },
                                    ],
                                    staticClass: "cursorP",
                                    attrs: { "icon-class": "underlineSelect" },
                                    on: {
                                      click: function ($event) {
                                        _vm.labelTemplateForm.lableTemplateContent.labelList[
                                          _vm.activeIndex
                                        ].underline = false
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.labelTemplateForm.lableTemplateContent
                                    .labelList[_vm.activeIndex].tagType ==
                                  "goodsBarcode",
                                expression:
                                  "\n                  labelTemplateForm.lableTemplateContent.labelList[\n                    activeIndex\n                  ].tagType == 'goodsBarcode'\n                ",
                              },
                            ],
                            staticClass: "controlGroup x-x",
                          },
                          [
                            _c("div", { staticClass: "controlGroupLabel" }, [
                              _c("span", [_vm._v("展示样式：")]),
                            ]),
                            _c("div", { staticClass: "controls" }, [
                              _c(
                                "div",
                                { staticClass: "wrapper" },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value:
                                          _vm.labelTemplateForm
                                            .lableTemplateContent.labelList[
                                            _vm.activeIndex
                                          ].barcodeType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.labelTemplateForm
                                              .lableTemplateContent.labelList[
                                              _vm.activeIndex
                                            ],
                                            "barcodeType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                        labelTemplateForm.lableTemplateContent.labelList[\n                          activeIndex\n                        ].barcodeType\n                      ",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "y-start" },
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              staticClass: "marB10",
                                              attrs: { label: 1 },
                                            },
                                            [_vm._v("展示为条码")]
                                          ),
                                          _c(
                                            "el-radio",
                                            {
                                              staticClass: "marB10",
                                              attrs: { label: 2 },
                                            },
                                            [_vm._v("展示为条码+数字")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.labelTemplateForm.lableTemplateContent
                                    .labelList[_vm.activeIndex].tagType ==
                                  "goodsBarcode",
                                expression:
                                  "\n                  labelTemplateForm.lableTemplateContent.labelList[\n                    activeIndex\n                  ].tagType == 'goodsBarcode'\n                ",
                              },
                            ],
                            staticClass: "controlGroup x-f",
                          },
                          [
                            _c("div", { staticClass: "controlGroupLabel" }, [
                              _c("span", { staticClass: "asterisk" }, [
                                _vm._v("*"),
                              ]),
                              _c("span", [_vm._v("条码大小：")]),
                            ]),
                            _c("div", { staticClass: "controls" }, [
                              _c(
                                "div",
                                { staticClass: "wrapper" },
                                [
                                  _c("span", { staticClass: "fontS14 marR8" }, [
                                    _vm._v("宽度"),
                                  ]),
                                  _c("el-input-number", {
                                    attrs: {
                                      "controls-position": "right",
                                      min: 0,
                                      max: 9999,
                                    },
                                    model: {
                                      value:
                                        _vm.labelTemplateForm
                                          .lableTemplateContent.labelList[
                                          _vm.activeIndex
                                        ].barcodeWidth,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.labelTemplateForm
                                            .lableTemplateContent.labelList[
                                            _vm.activeIndex
                                          ],
                                          "barcodeWidth",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "\n                        labelTemplateForm.lableTemplateContent.labelList[\n                          activeIndex\n                        ].barcodeWidth\n                      ",
                                    },
                                  }),
                                  _c("span", { staticClass: "fontS14 marR8" }, [
                                    _vm._v("高度"),
                                  ]),
                                  _c("el-input-number", {
                                    attrs: {
                                      "controls-position": "right",
                                      min: 0,
                                      max: 9999,
                                    },
                                    model: {
                                      value:
                                        _vm.labelTemplateForm
                                          .lableTemplateContent.labelList[
                                          _vm.activeIndex
                                        ].barcodeHeight,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.labelTemplateForm
                                            .lableTemplateContent.labelList[
                                            _vm.activeIndex
                                          ],
                                          "barcodeHeight",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "\n                        labelTemplateForm.lableTemplateContent.labelList[\n                          activeIndex\n                        ].barcodeHeight\n                      ",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.labelTemplateForm.lableTemplateContent
                                    .labelList[_vm.activeIndex].tagType ==
                                  "qrCode",
                                expression:
                                  "\n                  labelTemplateForm.lableTemplateContent.labelList[\n                    activeIndex\n                  ].tagType == 'qrCode'\n                ",
                              },
                            ],
                            staticClass: "controlGroup x-x",
                          },
                          [
                            _c("div", { staticClass: "controlGroupLabel" }, [
                              _c("span", [_vm._v("上传图片：")]),
                            ]),
                            _c("div", { staticClass: "controls" }, [
                              _c(
                                "div",
                                { staticClass: "wrapper" },
                                [
                                  _c("accessory-upload", {
                                    attrs: {
                                      listType: "picture-card",
                                      limit: 1,
                                      title: "",
                                    },
                                    on: {
                                      getFileItems: _vm.getFileItemsDataQRCode,
                                      delFileItems: _vm.delFileItemsQRCode,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.labelTemplateForm.lableTemplateContent
                                    .labelList[_vm.activeIndex].tagType ==
                                  "qrCode",
                                expression:
                                  "\n                  labelTemplateForm.lableTemplateContent.labelList[\n                    activeIndex\n                  ].tagType == 'qrCode'\n                ",
                              },
                            ],
                            staticClass: "controlGroup x-f",
                          },
                          [
                            _c("div", { staticClass: "controlGroupLabel" }, [
                              _c("span", { staticClass: "asterisk" }, [
                                _vm._v("*"),
                              ]),
                              _c("span", [_vm._v("图片大小：")]),
                            ]),
                            _c("div", { staticClass: "controls" }, [
                              _c(
                                "div",
                                { staticClass: "wrapper" },
                                [
                                  _c("span", { staticClass: "fontS14 marR8" }, [
                                    _vm._v("宽度"),
                                  ]),
                                  _c("el-input-number", {
                                    attrs: {
                                      "controls-position": "right",
                                      min: 0,
                                      max: 9999,
                                    },
                                    model: {
                                      value:
                                        _vm.labelTemplateForm
                                          .lableTemplateContent.labelList[
                                          _vm.activeIndex
                                        ].imageWidth,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.labelTemplateForm
                                            .lableTemplateContent.labelList[
                                            _vm.activeIndex
                                          ],
                                          "imageWidth",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "\n                        labelTemplateForm.lableTemplateContent.labelList[\n                          activeIndex\n                        ].imageWidth\n                      ",
                                    },
                                  }),
                                  _c("span", { staticClass: "fontS14 marR8" }, [
                                    _vm._v("高度"),
                                  ]),
                                  _c("el-input-number", {
                                    attrs: {
                                      "controls-position": "right",
                                      min: 0,
                                      max: 9999,
                                    },
                                    model: {
                                      value:
                                        _vm.labelTemplateForm
                                          .lableTemplateContent.labelList[
                                          _vm.activeIndex
                                        ].imageHeight,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.labelTemplateForm
                                            .lableTemplateContent.labelList[
                                            _vm.activeIndex
                                          ],
                                          "imageHeight",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "\n                        labelTemplateForm.lableTemplateContent.labelList[\n                          activeIndex\n                        ].imageHeight\n                      ",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }